// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-our-work-mdx": () => import("./../../../src/pages/our-work.mdx" /* webpackChunkName: "component---src-pages-our-work-mdx" */),
  "component---src-pages-publications-mdx": () => import("./../../../src/pages/publications.mdx" /* webpackChunkName: "component---src-pages-publications-mdx" */),
  "component---src-pages-services-shopify-packages-mdx": () => import("./../../../src/pages/services/shopify-packages.mdx" /* webpackChunkName: "component---src-pages-services-shopify-packages-mdx" */),
  "component---src-pages-start-a-project-mdx": () => import("./../../../src/pages/start-a-project.mdx" /* webpackChunkName: "component---src-pages-start-a-project-mdx" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

